import { mapMutations } from 'vuex'

import PageTitleMixin from '@/mixins/PageTitleMixin'
import ErrorOverlayMixin from '@/mixins/ErrorOverlayMixin'

const DESCRIPTION_META_TAG_ID = 'descriptionMetaTag'
const ROBOTS_META_TAG_ID = 'robotsMetaTag'

export default {
  mixins: [PageTitleMixin, ErrorOverlayMixin],
  computed: {
    pageCrumbs () {
      return []
    },
    pageTitle () {
      return this.$route.meta?.title
    },
    metaData () {
      return {}
    },
    isRobotsNoIndex () {
      return this.$route.path !== this.$route.fullPath
    }
  },
  methods: {
    ...mapMutations('crumbs', { setCrumbsData: 'SET_CRUMBS_DATA' }),
    setMetaTags () {
      this.unsetMetaTags()

      const createMetaTag = (id, name, content) => {
        const metaTag = document.createElement('meta')
        metaTag.id = id
        metaTag.name = name
        metaTag.content = content
        document.head.prepend(metaTag)
      }

      if (this.metaData?.description) {
        createMetaTag(DESCRIPTION_META_TAG_ID, 'description', this.metaData?.description)
      }

      createMetaTag(ROBOTS_META_TAG_ID, 'robots', this.isRobotsNoIndex ? 'noindex, nofollow' : 'all')
    },
    unsetMetaTags () {
      const removeMetaTag = (id) => {
        const el = document.getElementById(id)
        if (el) el.remove()
      }
      if (this.metaData?.description) {
        removeMetaTag(DESCRIPTION_META_TAG_ID)
      }

      removeMetaTag(ROBOTS_META_TAG_ID)
    }
  },
  created () {
    this.setPageTitle(this.pageTitle)
  },
  mounted () {
    this.setCrumbsData(this.pageCrumbs)
  }
}
